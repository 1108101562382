footer {
	background: $slate;
	color: $white;
	padding: 100px 0 50px 0;


	.logo {

		a {
			display: block;
			max-width: 100px;
		}
	}


	.wrapper {
		display: flex;
		flex-wrap: wrap;

		.col {
			flex-basis: 100%;
			padding: 0 0 25px 0;



			@include tablet-landscape {
				flex-basis: 33%;
				text-align: left;
				padding: 0;
			}
			
		}

	}

	h4 {
		text-transform: uppercase;
		font-size: 1.5em;
		line-height: 1em;
		padding: 0 0 20px 0;
	}

	p {
		font-size: 0.8125em;
		line-height: 1.375em;
		padding: 0 0 20px 0;
		font-weight: bold;
	}

	ul {

		li {
			padding: 0 0 10px 0;
			display: flex;
			align-items: center;

			&.divider {
				text-indent: -9999px;
			}

			.sep,
			a.sub-link {
				display: none;

				@include desktop {
					display: block;
				}				
			}

			.sep {
				padding: 0 5px;
			}

			a {
				font-size: 0.8125em;
				color: $white;

				&:hover {
					text-decoration: underline;
				}

				&.division {
					font-size: 1em;
					font-weight: bold;
				}
			}
		}
	}

	.copyright {
		width: 100%;
		text-align: left;
		display: flex;
		justify-content: space-between;
		align-items: center;

		margin: 100px 0 0 0;
		border-top: 1px solid #2a2a2a;
		padding: 20px 0 0 0;

		p {
			font-weight: normal;
			font-size: 0.75em;
			color: #666;
			padding: 0;

			&.credits {
				text-align: right;

				a {
					color: #666;
					border-bottom: 1px dotted;
				}
			}
		}
	}


}