body.page-template-history {

	section#main {

		article {
			@include tablet-landscape {
				width: 66.6666%;
			}

			h3 {
				color: $blue;
				padding: 20px 0 10px 0;
				font-size: 1.5em;
			}
		}

		.division + .division {
			padding: 50px 0 0 0;

			@include tablet-portrait {
				padding: 100px 0 0 0;
			}
		}

		table {
			width: 100%;

			thead {
				font-weight: bold;

				td {
					padding: 10px;
					border-bottom: 2px solid $slate;
					font-family: $roboto;
				}
			}

			.year {
				width: 10%;
			}

			.champion,
			.runner-up,
			.location {
				width: 30%;
			}

			tbody {
				tr:nth-child(even) {
					background: #fafafa;
				}
				td {
					border-top: 1px solid #eee;
					padding: 12px 10px;
					font-size: 0.875em;
					font-family: $roboto;

					&.champion {
						font-weight: bold;
					}
				}
			}
		}
	}



}