header {
	padding: 30px 0;

	.wrapper {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.logo {
		display: flex;
		align-items: center;

		img {
			margin-right: 20px;

		}
		h1 {
			display: none;
			text-transform: uppercase;
			font-size: 2.25em;
			letter-spacing: 0.025em;
			font-family: $open-sans;
			font-weight: 300;

			@include mobile-landscape {
				display: block;
			}

			@include tablet-landscape {
				font-size: 3.5em;
			}
			a {
				color: $red;
			}
		}
	}


	#toggle {
		width: 34px;
	    cursor: pointer;
	    padding: 8px 5px 8px 5px;
	    transition: all 0.6s cubic-bezier(0.7, 0.01, 0.3, 1);
	    margin-right: 10px;

		.patty {
		    width: 40px;
		    height: 5px;
		    background: $red;
		    transition: all 0.3s;

			&:after, &:before {
			    content: '';
			    display: block;
			    width: 40px;
			    height: 5px;
			    background: $red;
			    position: absolute;
			    z-index: -1;
			    transition: all 0.3s;
			}
			&:before {
			    top: -10px;
			}
			&:after {
			    top: 10px;
			}
		}
	}

	&.open {

		#toggle .patty {
			background: none;

			&:after {
			    top: 0;
			    transform: rotate(45deg);
			}
			&:before {
			    top: 0;
			    transform: rotate(-45deg);
			}
		}

	}


	a.watch-live {
		margin: 0 20px 0 auto;
		display: inline-block;
		padding: 8px 12px 6px 12px;
		color: $blue;
		border: 1px solid $blue;
		font-weight: 700;
		font-size: 0.875em;
		text-transform: uppercase;
		letter-spacing: 0.05em;

		&:hover {
			background: $blue;;
			color: $white;
		}

		@include tablet-landscape {

		}
	}



}

nav#dropdown {
	display: none;
	background: $light-gray;
	padding: 25px 0 10px 0;

	@include tablet-portrait {
		padding: 50px 0;
	}

	.wrapper {
		display: flex;
		flex-wrap: wrap;

		.col {
			flex-basis: 100%;

			& + .col {
				padding-top: 25px;
			}

			@include mobile-landscape {
				flex-basis: 40%;
				& + .col {
					padding-top: 0;
				}

				&.news {
					flex-basis: 20%;
				}
			}

			@include tablet-landscape {
				flex-basis: 50%;
				text-align: left;
				padding: 0;
				
				&.news {
					display: none;
				}
			}
		}
	}


	h4 {
		text-transform: uppercase;
		font-size: 1.25em;
		line-height: 1em;
		padding: 0 0 20px 0;
		color: $blue;
		font-weight: bold;
		font-family: $open-sans;

		@include tablet-landscape {
			font-size: 2em;
		}
	}

	p {
		font-size: 0.875em;
		line-height: 1.375em;
		padding: 0 0 20px 0;
	}

	ul {
		li {
			padding: 0 0 15px 0;
			display: flex;
			align-items: center;
			flex-wrap: wrap;

			&.divider {
				text-indent: -9999px;
			}


			span.sep {
				display: none;
				padding: 0 5px;
				color: $red;
				font-weight: bold;

				@include tablet-landscape {
					display: block;
				}	



				@include tablet-landscape {
					display: inline-block;
				}
			}


			a {
				font-weight: bold;

				&:hover {
					text-decoration: underline;
				}

				&.division {
					font-family: $open-sans;
					font-size: 1em;
					padding-bottom: 5px;
					color: $blue;

					@include tablet-landscape {
						font-size: 1.25em;
						padding-bottom: 0;
					}
				}

				&.sub-link {
					font-weight: 400;
					font-size: 0.875em;
					width: 100%;;
					padding: 5px 0 5px 15px;
					color: $red;


					@include tablet-landscape {
						width: auto;
					}

				}
			}
		}
	}




}

nav#bar {
	background: $blue;
	color: $white;
	padding: 20px 0;

	.wrapper {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: space-between;

		@include tablet-landscape {
			justify-content: flex-start;
		}
	}

	h1 {
		text-transform: uppercase;
		font-size: 1.5em;

		@include tablet-portrait {
			font-size: 1.875em;
		}

		a {
			color: $white;
		}
	}

	#menu-toggle {
		width: 32px;
		height: 18px;
		background: url(images/menu-toggle.png) no-repeat 0 0;
		background-size: cover;
		display: block;
		text-indent: -99999px;		
		margin: 0 3px 0 0;

		@include tablet-landscape {
			display: none;
		}
	}

	.menu {
		display: none;
		width: 100%;
		padding: 10px 0 0 0;

		@include tablet-landscape {
			display: flex !important;
			width: auto;
			padding: 0;
		}


		a {
			color: $white;
			display: block;
			font-size: 1.5em;
			font-weight: bold;
			margin: 20px 0;

			@include tablet-landscape {
				margin: 0 0 0 20px;
				font-size: 1.125em;
			}

			@include desktop {
				margin: 0 0 0 40px;
			}

			&:hover {
				text-decoration: underline;
			}
		}

		.mobile.subnav {
			margin: 10px 0 0 0;
			border-top: 1px solid rgba(255, 255, 255, 0.5);

			a {
				font-weight: normal;
				font-size: 1.125em
			}


			@include tablet-landscape {
				display: none;
			}
		}
	}

}


#sub-bar {
	background: $red;
	padding: 15px 0;
	display: none;

	@include tablet-landscape {
		display: block;
	}
	
	.wrapper {

		@include tablet-portrait {
			display: flex !important;
		}

	}

	a {
		color: $white;
		display: block;
		font-size: 1em;
		margin: 10px 0;
		font-weight: bold;

		@include tablet-portrait {
			font-size: 0.875em;
			margin: 0 40px 0 0;
		}

		&:hover {
			text-decoration: underline;
		}
	}


}


#utility-nav {
	background: $light-gray;
	padding: 12px 0 10px 0;
	border-bottom: 1px solid rgba(150, 201, 61, 0.2);
	display: none;

	@include tablet-portrait {
		display: block;
	}

	.wrapper {
		display: flex;
		justify-content: flex-end;
	}

	div.section {
		display: flex;
		margin: 0 0 0 50px;
		align-items: center;

		a, span {
			font-size: 0.75em;

			@include tablet-landscape {
				font-size: 0.8125em;
			}
		}

		span.dot {
			display: inline-block;
			padding: 0 5px;
		}
		strong {
			padding: 0 10px 0 0;
			font-size: 0.8125em;
			text-transform: uppercase;
			color: $blue;
		}
	}

	a {
		font-weight: bold;
		display: inline;
		color: $red;
		font-weight: 400;

		&.news {
			font-weight: bold;
			font-size: 0.8125em;
		}

		&:hover {
			text-decoration: underline;
		}

	}

}