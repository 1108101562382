body.page-template-rankings {
	section#main {

		.update {
			padding: 0 0 50px 0;
		}

		.full-rankings {
			padding: 50px 0 0 0;
			text-align: center;
			width: 100%;

			a.btn {
				display: inline-block;
			}
		}


		table.global_table {
			width: 100%;


			a {
				color: $slate;

				&:hover {
					cursor: default;
				}
			}

			tr {

				th:nth-child(4),
				th:nth-child(5),
				th:nth-child(6),
				th:nth-child(7),
				th:nth-child(8),
				th:nth-child(9),
				th:nth-child(10),
				th:nth-child(11) {
					display: none;
				}


				td:nth-child(4),
				td:nth-child(5),
				td:nth-child(6),
				td:nth-child(7),
				td:nth-child(8),
				td:nth-child(9),
				td:nth-child(10),
				td:nth-child(11) {
					display: none;
				}

				td, th {
					text-align: left;
					vertical-align: middle;
				}

				th {
					font-size: 0.875em;
					padding: 15px;

					@include tablet-portrait {
						font-size: 1em;
					}
				}

				& + tr td {
					border-top: 1px solid #eee;
					padding: 15px;
					font-size: 0.875em;

					@include tablet-portrait {
						font-size: 1em;
					}
				}

				&:last-of-type {
					display: none;
				}

				th:nth-child(1),
				td:nth-child(1) {
					font-weight: bold;
				}


				// Team
				th:nth-child(2),
				td:nth-child(2) {
					font-weight: bold;
				}



				@include tablet-portrait {
					// Rank
					th:nth-child(1),
					td:nth-child(1) {
						width: 5%;
						font-weight: bold;
						text-align: center;
					}


					// Team
					th:nth-child(2),
					td:nth-child(2) {
						width: 20%;
						font-weight: bold;
					}

					// Power Rating
					th:nth-child(3),
					td:nth-child(3) {
						width: 10%;
						border-right: 1px solid #eee;
						text-align: center;
					}

					// Wins
					th:nth-child(10),
					td:nth-child(10) {
						width: 10%;
						border-left: 1px solid #eee;
						text-align: center;
						display: table-cell;
					}

					// Losses
					th:nth-child(11),
					td:nth-child(11) {
						width: 10%;
						text-align: center;
						display: table-cell;
					}
				}



				@include tablet-landscape {
					// City
					th:nth-child(6),
					td:nth-child(6) {
						width: 10%;
						text-align: center;
						display: table-cell;
					}

					// State
					th:nth-child(7),
					td:nth-child(7) {
						width: 10%;
						text-align: center;
						display: table-cell;
					}

				}



			}

			table {
				display: none;
			}

		}


	}
}