body.page-template-awards {

	section#main {

		article {
			@include tablet-landscape {
				width: 75%;
			}

			h3 {
				color: $red;
				padding: 20px 0 10px 0;
				font-size: 1.5em;
			}
		}

		.division + .division {
			padding-top: 100px;
		}

		table {
			width: 100%;

			thead {
				font-weight: bold;

				td {
					padding: 10px;
					border-bottom: 2px solid $blue;
					font-family: $open-sans;
					color: $blue;
				}
			}

			.year {
				width: 10%;
			}

			.individual-spirit {
				width: 60%;

			}
			.team-spirit {
				width: 30%;
			}

			tbody {
				tr:nth-child(even) {
					background: #fafafa;
				}
				td {
					border-top: 1px solid #eee;
					padding: 12px 10px;
					font-size: 0.875em;
					font-family: $roboto;

					&.year {
						font-weight: bold;
					}
				}
			}
		}
	}



}