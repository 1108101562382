/* Colors
----------------------------------*/
$white: #fff;
$black: #000;
$slate: #2a2a2a;
$light-gray: #FCFCFC;
$red: #b20838;
$blue: #002a5c;


$orange: #FFA409;
$green: #3BAF29;
$light-green: #96C93D;
$dark-green: #166936;



/* Typography
----------------------------------*/
$merriweather: 'Merriweather', serif;
$open-sans: 'Open Sans Condensed', sans-serif;
$roboto: 'Roboto Condensed', sans-serif;

