section#news{
	padding: 30px 0;

	@include tablet-portrait {
		padding: 50px 0;
	}

	.wrapper {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: flex-start;
	}

	article {
		width: 100%;
		padding: 0 0 75px 0;

		@include mobile-landscape {
			width: 47.5%;
		}

		.info {
			background: $white;
			border: 1px solid rgba(0, 42, 92, 0.2);
			box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1);
			padding: 20px;
			width: 95%;
			margin: -20px auto 0 auto;

			@include mobile-landscape {
				margin: -20px auto 0 auto;
				padding: 40px;
				width: 90%;
			}

			h4 {
				color: $red;
				font-size: 0.875em;
				padding: 0 0 5px 0;

				@include mobile-landscape {
					font-size: 1em;
				}

			}

			h3 {
				font-size: 1.5em;
				padding: 0 0 20px 0;

				@include mobile-landscape {
					font-size: 2em;
				}

				a {
					color: $blue;
				}
			}

			p {
				font-family: $merriweather;
				font-size: 0.8125em;
				line-height: 1.75em;
			}


		}


	}

	#ajax-load-more {
		width: 100%;
	
		.alm-listing {
			width: 100%;
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;



		}

	}

	.alm-btn-wrap {
		text-align: center;
		width: 100%;

		button {
			border: none;
			margin: 0 auto;

			&:active,
			&:focus {
				outline: none;
			}

			&.done {
				display: none;
			}
		}
	}


}