body.page-template-regular-season {

	section#main {

		.tournament {
			padding: 50px 0;

			& + .tournament {
				border-top: 1px solid #eee;
			}

			h3 {
				color: $red;
				font-size: 2em;
				font-weight: 300;
				padding: 0;
			}

			h4 {
				padding: 5px 0 15px 0;
				font-size: 1.125em;
			}

			h5 {
				font-size: 1.125em;
				font-weight: 300;
			}

			a.btn {
				text-decoration: none;
				display: inline-block;
				font-size: 0.875em;
				margin: 15px 0 0 0;

			}


		}




	}
}