body#home {

	section#main {
		.wrapper {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			align-items: flex-start;
		}

		section#tiles {
			width: 100%;

			@include mobile-portrait {
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;

				.col {
					width: 47.5%;
					display: flex;
					flex-wrap: wrap;
					justify-content: space-between;

					@include mobile-landscape {
						width: 48%;
					}

					@include tablet-portrait {
						width: 100%;
					}

					@include tablet-landscape {
						width: 48.5%;
					}

					@include desktop {
						width: 49%;
					}

				}
			}

			@include tablet-portrait {
				width: 65%;
			}

			@include desktop {
				width: 78.5%;
			}

			.tile {
				display: block;
				width: 100%;
				margin: 0 0 20px 0;
				position: relative;


				&:after {
				  content: "";
				  display: block;
				  padding-bottom: 50%;

					@include mobile-portrait {
						padding-bottom: 100%;
					}
				}

				@include tablet-portrait {
					width: 47.5%;

					&-1,
					&-6 {
						width: 100%;
					}

				}

				@include tablet-landscape {
					width: 47%;

					&-1,
					&-6 {
						width: 100%;
					}

					&-2,
					&-3,
					&-6 {
						margin-bottom: 0;
					}

				}

				@include desktop {
					width: 47.5%;

					&-1,
					&-6 {
						width: 100%;
					}

				}

				.label {
					left: 5%;
					bottom: 5%;
					position: absolute;
					max-width: 90%;
					font-family: $open-sans;
					text-transform: uppercase;
					font-size: 1.5em;
					padding: 10px;
					color: $white;
					background: rgba(0, 42, 92, 0.8);
					display: inline-block;

					@include mobile-landscape {
						font-size: 1em;
					}

					@include tablet-portrait {
						font-size: 1.5em;
					}

					@include tablet-landscape {
						font-size: 1em;
					}

					@include desktop {
						font-size: 1.25em;
					}
				}

				@include tablet-landscape {

					&-1,
					&-6 {
						.label {
							font-size: 1.5em;
						}
					}
				}

				@include desktop {

					&-1,
					&-6 {
						.label {
							font-size: 2.5em;
						}
					}
				}
			}

		}

		aside#latest-news {
			width: 100%;
			padding: 50px 0;

			@include tablet-portrait {
				width: 30%;
				padding: 0;
			}

			@include desktop {
				width: 18.5%;
			}

		}



	}



}