body.page-template-postseason {

	section#main {

		article.default {
			max-width: 640px;
			margin: 0 auto;
			padding: 50px 0;
		}

		.round-nav {
			display: flex;
			justify-content: center;
			flex-wrap: wrap;
			padding: 0 0 10px 0;
			border-bottom: 1px solid #e5e5e5;
			margin: 0 0 50px 0;

			a {
				margin: 5px;
				background: $blue;
				color: $white;
				text-decoration: none;
				font-family: $roboto;
				font-weight: bold;
				padding: 9px 12px 8px 12px;
				font-size: 0.8125em;
			}
		}

		section.round {

			& + section.round {
				padding: 100px 0 0 0;
			}

			h2.round-name {
				font-weight: 300;
				text-align: center;
				color: $blue;
				text-transform: none;
				font-size: 2em;
				padding: 0 0 50px 0;


				@include tablet-portrait {
					font-size: 3em;
				}

				em {
					font-style: normal;
					font-weight: 700;
					color: $red;
				}
			}

			.region {
				flex-wrap: wrap;
				justify-content: center;
				text-align: center;


				h3.region-name {
					padding: 0 0 0 0;
					font-size: 1.75em;
					width: 100%;

				}

				h5 {
					padding: 0 0 20px 0;
				}

				.btn {
					display: inline-block;
					font-size: 0.8125em;
					text-decoration: none;
				}

				.sections {
					display: flex;
					flex-wrap: wrap;
					justify-content: center;
					width: 100%;

					.section {
						width: 100%;
						padding: 50px 0  0 0;

						@include mobile-landscape {
							width: 50%;
						}

					}
				}
			}


			&#sectionals {
				padding: 0 0 100px 0;
				border-bottom: 3px solid #e5e5e5;

				.region {
					display: none;
				}

				.section {
					h3 {
						color: $red;
						text-transform: uppercase;
						font-weight: 300;
						font-size: 1.25em;
						letter-spacing: 0.05em;
						padding: 0 0 10px 0;
					}

					h4 {
						text-transform: none;
						padding: 0 0 10px 0;
						letter-spacing: 0;
						font-size: 0.875em;
					}
				}
			}

			&#regionals {
				padding: 100px 0 100px 0;
				border-bottom: 3px solid #e5e5e5;

				.region {
					h3 {
						font-weight: 700;
						font-size: 1.75em;
						padding: 0 0 10px 0;
					}

					h4 {
						text-transform: none;
						padding: 0 0 10px 0;
						letter-spacing: 0;
						font-size: 0.875em;
						color: $red;
					}
				}
			}

			&#nationals {


				.region {
					h4 {
						font-weight: 700;
						font-size: 1em;
						text-transform: none;
						padding: 0 0 20px 0;
					}


				}
			}

		}


	}
}