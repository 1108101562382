body.template-watch-live {

	section#main {
		article {
			width: 100%;
			max-width: 800px;
			padding: 0 0 25px 0;
		}
	}


	section.schedule {
		width: 100%;
		max-width: 800px;
		padding: 0 0 100px 0;

		&:last-of-type {
			padding: 0;
		}

		.day {
			padding: 0 0 100px 0;

			&:last-of-type {
				padding: 0;
			}
		}

		h3 {
			font-size: 2em;
			padding: 0 0 40px 0;
		}

		h4 {
			color: $red;
			font-size: 1em;
			text-transform: uppercase;
			letter-spacing: 0.05em;
			padding: 0 0 10px 0;
		}

		table {
			width: 100%;

			.division {
				width: 25%;
			}

			.match-up {
				width: 25%;
			}

			.time {
				width: 25%;
			}

			.link {
				width: 25%;
			}

			thead {
				tr {
					border-bottom: 2px solid $slate;

					th {
						text-align: left;
						padding: 10px;
						font-size: 0.875em;
					}
				}
			}

			tbody {
				td {
					padding: 10px;
					font-size: 0.875em;

					a:hover {
						text-decoration: underline;
					}
				}

			}

		}

	}

}