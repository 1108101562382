body.page-template-teams,
body.page-template-schedule-results  {

	#teams-wrapper {
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-row-gap: 50px;
		grid-column-gap: 25px;

		@include mobile-landscape {
			grid-template-columns: 1fr 1fr 1fr;

		}

		@include tablet-landscape {
			grid-template-columns: 1fr 1fr 1fr 1fr;
			grid-row-gap: 100px;
		}

		.team {

			.logo {
				

				img {
					margin: 0 auto;
					max-width: 96px;
				}
			}

			.info {
				text-align: center;
				padding: 10px 0 0 0;

				h3 {
					font-family: $roboto;
					font-size: 0.875em;
					letter-spacing: 0.05em;
					text-transform: uppercase;

					a {
						color: $slate;
					}

				}
			}

		}

	}


}