body.template-photo-essay {

	#photo-essay {
		max-width: 1000px;
		margin: 0 auto;
		
		.photo {
			background: #1a1a1a;
			color: $white;
			padding: 20px;
			margin: 0 0 20px 0;

			.image {
				display: table;
				margin: 0 auto;

				img {
					margin: 0 auto;
				}

				.credit {
					position: absolute;
					right: 0;
					bottom: 0;

					span {
						display: inline-block;
						padding: 10px 15px 8px 15px;
						font-family: $open-sans;
						text-transform: uppercase;
						font-weight: bold;
						font-size: 0.75em;
						color: $white;
						background: rgba(188, 169, 91, 0.8);

						@include  mobile-landscape {
							font-size: 1em;
						}
					}
				}
			}

			.caption {
				padding: 20px 0 0 0;

				@include  mobile-landscape {
					padding: 20px 20px 0 20px;
				}

				p {
					font-family: $merriweather;
					font-size: 0.75em;
					line-height: 1.5em;

					@include  mobile-landscape {
						font-size: 0.8125em;
					}
				}
			}
		}


	}


}

body.template-photo-index {

	#galleries {
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: flex-start;


		@include mobile-landscape {
			min-height: 600px;
		}


		.photo-essay {
			margin: 0 0 20px 0;
			width: 100%;
			background: #1a1a1a;
			color: $white;
			padding: 20px;

			@include mobile-landscape {
				width: 47.5%;
			}


			.image {
				display: flex;
				justify-content: center;
			}



			.info {
				padding: 20px 0 0 0;
				text-align: center;

				h4 {
					font-size: 1.25em;
					font-family: $open-sans;
					text-transform: uppercase;

					a {
						color: $white;

					}
				}

				p {
					font-family: $merriweather;
					font-size: 0.75em;
					font-style: italic;
					padding: 10px 0 0 0;
				}


			}

		}
	}


}