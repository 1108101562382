/*

    Theme Name: Triple Crown Tour
    Theme URI: http://triplecrowntour.usaultimate.org/
    Author: Andrew Lovseth
    Author URI: http://www.andrewlovseth.com/
    Description: Version 1.0 of Triple Crown Tour
    Version: 1.0

*/






/*

    ----------------------------------------------------------------------
                                01 Global
    ----------------------------------------------------------------------

*/

@import "global/variables";
@import "global/mixins";
@import "global/reset";
@import "global/typography";
@import "global/layout";

@import "plugins/slick";
@import "plugins/slick-theme";




/*

    ----------------------------------------------------------------------
                                02 Sections
    ----------------------------------------------------------------------

*/

@import "header";
@import "footer";
@import "sponsors";
@import "page";



/*

    ----------------------------------------------------------------------
                                03 Templates
    ----------------------------------------------------------------------

*/

@import "home";
@import "overview";
@import "division";
@import "schedule-results";
@import "teams";
@import "history";
@import "watch-live";
@import "news";
@import "single-post";
@import "single-team";
@import "photos";
@import "videos";
@import "press-info";
@import "password-protected-photos";
@import "rankings";
@import "regular-season";
@import "postseason";
@import "awards";


